import { hasCobaltAuthToken } from 'services/cookies'

const REDIRECT_ROUTES = [
  { route: '#/registrations', regex: /users\/sign_up/ },
  { route: '#/grader', regex: /product-listing-grader/ },
  { route: '#/reset-password', regex: /users\/passwords\/new/ },
  { route: '#/reset-password', regex: /users\/password\/new/ },
  { route: '#/ppc-sync-callback', regex: /ppc-sync-callback/ },
  { route: '#/mws-sync-callback', regex: /mws-sync-callback/ }
]

export const getMatchingRedirectRoute = () => {
  const { pathname } = window.location

  for (const item of REDIRECT_ROUTES) {
    if (pathname.match(item.regex)) {
      return pathname.replace(item.regex, item.route) + window.location.search
    }
  }
}

const buildRedirectRoute = () => {
  const path = window.location.pathname.replace(/\/$/, '')
  const hash = window.location.hash.replace(/#\//, '')
  const search = hash.includes('?') ? '' : window.location.search

  return encodeURIComponent(`${path}/${hash}${search}`)
}

export const redirectToLogin = () => {
  setTimeout(() => {
    if (window.location.search.includes('amazon_callback_uri')) {
      window.location.assign(
        `${process.env.REACT_APP_CLIENT_LOGIN}/${window.location.search}`
      )
    } else {
      const searchParams = new URLSearchParams(window.location.search)
      const redirectRoute =
        searchParams.get('redirectRoute') || buildRedirectRoute()

      window.location.assign(
        `${process.env.REACT_APP_CLIENT_LOGIN}/?redirectRoute=${redirectRoute}`
      )
    }
  }, 500)
}

export const checkLoginRedirection = () => {
  const needsRedirection =
    process.env.NODE_ENV === 'production' &&
    hasCobaltAuthToken() &&
    !window?.location?.origin?.includes('cobalt')

  if (needsRedirection) {
    redirectToLogin()
  }

  return needsRedirection
}
