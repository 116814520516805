import moment from 'moment'
import React from 'react'
import { Translation } from 'react-i18next'

import { PRODUCT_LAUNCH_DATE } from 'constants/product_costs'
import { EMPTY_MARKER } from 'constants/table'
import { isSameDay, humanizeDate, isToday, daysBeforeDate } from './date'
import { replaceAtIndex } from './arrays'

// This assumes the costs are already sorted by date_effective
// This will have to change when we add the proper is_active logic to the API side
// it will also not work with future costs, where we should display an empty state
export const addEffectiveToDate = costs => {
  const sortedCosts = sortByDateEffective(costs)

  return sortedCosts.map((c, i) => {
    return {
      ...c,
      effective_to: getEffectiveTo(c, sortedCosts[i - 1])
    }
  })
}

export const getEffectiveTo = (cost, prevCost) => {
  const today = new Date()
  const prevDateEffective = prevCost ? new Date(prevCost.date_effective) : null

  if (moment(cost.date_effective).isAfter(today, 'day')) {
    return null
  }

  return prevDateEffective ? daysBeforeDate(prevDateEffective, 1) : today
}

export const getUpdatedProductCosts = (updatedCost, costItems) => {
  const oldCostIndex = costItems.findIndex(c => c.id === updatedCost.id)
  const updatedCosts = replaceAtIndex(costItems, oldCostIndex, updatedCost)

  return addEffectiveToDate(updatedCosts)
}

// Ensure dates are sorted by date_effective
export const sortByDateEffective = costs =>
  [...costs].sort((c1, c2) => {
    return c1.date_effective > c2.date_effective ? -1 : 1
  })

export const getBlockedDates = (productCosts, selectedCost) => {
  if (!productCosts) return []

  return productCosts
    .filter(cost => cost.id !== selectedCost.id)
    .map(cost => moment(cost.date_effective))
}

export const setActiveCost = product => {
  if (!product.product_costs || product.product_costs.length === 0) {
    return {
      ...product,
      activeCost: {}
    }
  }

  const activeCost = product.product_costs.find(c => c.is_active === true)

  return {
    ...product,
    activeCost: activeCost || {}
  }
}

export const formatProductData = product => {
  const withEffectiveTo = {
    ...product,
    product_costs: addEffectiveToDate(product.product_costs || [])
  }

  return setActiveCost(withEffectiveTo)
}

export const formatEffectiveTo = date => {
  if (isToday(date)) {
    return (
      <Translation ns="generic">
        {t => t('product_cost:formatDate.present', 'Present')}
      </Translation>
    )
  }

  return humanizeDate(date)
}

export const formatEffectiveFrom = date => {
  if (isSameDay(moment(date), PRODUCT_LAUNCH_DATE)) {
    return (
      <Translation ns="product_cost">
        {t => t('product_cost:formatDate.sinceLaunch', 'Since Product Launch')}
      </Translation>
    )
  }

  return humanizeDate(date)
}

export const newProductCost = (product_id, amazon_seller_account_id) => {
  return {
    product_id,
    amazon_seller_account_id,
    unit_cost: '',
    shipping_cost: '',
    misc_cost: ''
  }
}

export const createExistingProductCost = cost => {
  return cost
    ? {
        id: cost.id,
        product_id: cost.product_id,
        amazon_seller_account_id: cost.amazon_seller_account_id,
        unit_cost: isNaN(cost.unit_cost) ? null : cost.unit_cost,
        shipping_cost: isNaN(cost.shipping_cost) ? null : cost.shipping_cost,
        misc_cost: isNaN(cost.misc_cost) ? null : cost.misc_cost,
        lead_time_in_days: isNaN(cost.lead_time_in_days)
          ? null
          : cost.lead_time_in_days
      }
    : {}
}

export const calcTotalCost = costs => {
  return costs
    ? (costs.shipping_cost || 0) +
        (costs.unit_cost || 0) +
        (costs.misc_cost || 0)
    : 0
}

export const isProductCostImportComplete = res => {
  if (res?.error) {
    return res
  }
  const status = res?.data?.job_status

  if (status === 'success' || status === 'error') {
    return { ...res, pollCompleted: true }
  }
  return res
}

export const formatLastSyncedAt = value =>
  value ? new Date(value).toLocaleString() : EMPTY_MARKER
