import moment from 'moment'
import { getLocalData, setLocalData } from '../storage'

function parseDate(value) {
  return value ? moment(value) : null
}

export function getCalendarModalPreferencesFromLocalStorage(
  key,
  fallbackValue
) {
  const storedValue = getLocalData(key)
  if (storedValue) {
    // Need to convert dates back to moment
    const parsedValue = JSON.parse(storedValue)
    return {
      ...parsedValue,
      startDate: parseDate(parsedValue.startDate),
      endDate: parseDate(parsedValue.endDate),
      comparisonStartDate: parseDate(parsedValue.comparisonStartDate),
      comparisonEndDate: parseDate(parsedValue.comparisonEndDate)
    }
  }
  return fallbackValue
}

export function setCalendarModalPreferencesInLocalStorage(key, dates) {
  setLocalData(key, JSON.stringify(dates))
}
