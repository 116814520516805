export const isProductSyncInProgress = (selectedAccount, mwsSync) => {
  if (
    !mwsSync ||
    !Array.isArray(mwsSync.status.amazon_seller_accounts) ||
    !selectedAccount
  ) {
    return false
  }

  const syncProgress = mwsSync.status.amazon_seller_accounts.find(
    a => a.id === selectedAccount.id
  )

  return !!(
    syncProgress &&
    syncProgress.mws_product != null &&
    syncProgress.mws_product < 100
  )
}

export const productImageResize = (url, size = 280) => {
  return url && url.replace('75_.', `${size}_.`)
}

export const parseVariantProductDifferences = product => {
  const { dimensionValuesDisplayData, asin } = product

  const variantObj = dimensionValuesDisplayData?.find(
    variant => variant.asin === asin
  )

  return variantObj?.differences
}
