import React from 'react'
import styled from 'styled-components'

import { Icon, Tooltip } from '@junglescout/edna'

import { COLORS } from 'COLORS'
import { DATABASE_TOOLTIPS } from 'constants/product_database/tooltips'

import { isValidValue } from 'helpers/values'
import { parseCurrency } from 'helpers/currency'
import { calcRevenue } from 'helpers/database/core'

import { NoDataTooltip } from 'ui_elements/NoDataTooltip/NoDataTooltip'

import { InfoIcon } from 'icons/InfoIcon/InfoIcon'
import { UnsupportedCategoryCell } from 'components/table_cells/UnsupportedCategoryCell'

const renderNaTooltip = (content, value = undefined) => {
  return <NoDataTooltip value={value} content={content} />
}

export const formatProductListingQualityScore = listingQualityScore => {
  if (!isValidValue(listingQualityScore))
    return renderNaTooltip(DATABASE_TOOLTIPS.nullProductData.noLQS)

  return Math.round(listingQualityScore / 10)
}

const RevenueTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`

export const formatProductRank = (
  rank,
  { bsr_product, variants, isSharedBSR }
) => {
  if (variants && !isSharedBSR) {
    return (
      <Tooltip
        side="bottom"
        content={DATABASE_TOOLTIPS.nullProductData.noParentRank}
        size="medium">
        <span>-</span>
      </Tooltip>
    )
  }

  if (!bsr_product)
    return renderNaTooltip(
      DATABASE_TOOLTIPS.nullProductData.noBsrRank,
      undefined
    )

  if (!isValidValue(rank) || isNaN(parseInt(rank, 10)))
    return renderNaTooltip(DATABASE_TOOLTIPS.nullProductData.rank, undefined)

  return parseInt(rank, 10).toLocaleString()
}

export const formatProductEstimatedSales = (
  estimatedSales,
  {
    hasRankFromApi,
    subCategory,
    unsupportedCategory,
    isEstimatedSalesWrongOrder
  }
) => {
  if (unsupportedCategory) {
    return <UnsupportedCategoryCell />
  }

  if (!isValidValue(estimatedSales))
    return renderNaTooltip(
      DATABASE_TOOLTIPS.nullProductData.noEstSales,
      undefined
    )

  const displayValue =
    estimatedSales < 5 ? '< 5' : parseInt(estimatedSales, 10).toLocaleString()

  if (hasRankFromApi && !subCategory)
    return renderNaTooltip(
      DATABASE_TOOLTIPS.nullProductData.rankFromApi,
      displayValue
    )

  if (isEstimatedSalesWrongOrder)
    return (
      <Tooltip
        side="bottom"
        content={DATABASE_TOOLTIPS.exceedFilterRange.estSales}
        size="medium">
        <span>
          {displayValue}
          <InfoIcon color={COLORS.graphRed} />
        </span>
      </Tooltip>
    )

  return displayValue
}

export const formatProductEstimatedRevenue = (
  estRevenue,
  {
    estimatedSales,
    bsr_product,
    price,
    hasRankFromApi,
    currency_code,
    subCategory,
    unsupportedCategory,
    maxRevenueInfilter,
    isSharedBSR
  }
) => {
  if (unsupportedCategory) {
    return <UnsupportedCategoryCell />
  }

  if (!isValidValue(estimatedSales) || !bsr_product)
    return renderNaTooltip(
      DATABASE_TOOLTIPS.nullProductData.noSalesEstRevenue,
      undefined
    )

  if (!isValidValue(price))
    return renderNaTooltip(
      DATABASE_TOOLTIPS.nullProductData.noPriceEstRevenue,
      undefined
    )

  const sales = parseInt(estimatedSales, 10)
  const revenue = calcRevenue({ estRevenue, price, sales })
  const formattedRevenue = parseCurrency(revenue, currency_code)
  const displayValue = sales < 5 ? `< ${formattedRevenue}` : formattedRevenue

  if (hasRankFromApi && !subCategory)
    return renderNaTooltip(
      DATABASE_TOOLTIPS.nullProductData.rankFromApi,
      displayValue
    )

  if (estRevenue > maxRevenueInfilter && !isSharedBSR) {
    return (
      <Tooltip
        size="medium"
        side="bottom"
        content={DATABASE_TOOLTIPS.exceedFilterRange.estRevenue}>
        <RevenueTooltipWrapper>
          {displayValue}
          <Icon
            name="TOOLTIP_INFO"
            color={COLORS.grey400}
            height="16px"
            width="16px"
          />
        </RevenueTooltipWrapper>
      </Tooltip>
    )
  }

  return displayValue
}

export const formatProductWeight = (weight, { weightUnit }) => {
  if (weight || weight === 0) {
    const unit = weightUnit === 'kilograms' ? 'kg' : 'lbs'

    return `${weight} ${unit}`
  }

  return renderNaTooltip(DATABASE_TOOLTIPS.nullProductData.weight)
}
